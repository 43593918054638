import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/__variables";

const AddressElem = styled.address`
  color: white;
  font-style: normal;
  font-family: "Montserrat Light";
  font-size: 16px;
  line-height: 24px;
  max-width: 600px;
  margin: ${`${spacers.mobile}px`} 0;

  a {
    color: white;
    text-decoration: none;
  }

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    font-size: 20px;
    line-height: 29px;
    margin: ${`${spacers.tablet}px`} 0;
  }

  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const Address: React.FC = () => {
  return (
    <AddressElem>
      RundumHR GmbH
      <br />
      Andrea Rechsteiner
      <br />
      Ob. Flurweg 118a
      <br />
      3072 Ostermundigen
      <br />
      <a href="tel:+41792212198">079 221 21 98</a>
      <br />
      <a href="mailto:andrea.rechsteiner@rundumhr.ch">
        andrea.rechsteiner@rundumhr.ch
      </a>
      <br />
      <br />
      <a href="/datenschutz">Datenschutzerklärung</a>
      <br />
    </AddressElem>
  );
};

export default Address;
