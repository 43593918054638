import React from "react";
import styled from "styled-components";
import { spacers, breakpoints, colors } from "../styles/__variables";
import Title from "./Title";
import Paragraph from "./Paragraph";
import Address from "./Address";
import Spacer from "../layouts/StandardSpacer";

const RoundContentDiv = styled.div`
  background: ${colors.blue.base};
  margin: 0 ${`-${spacers.mobile}px`} ${`-${spacers.mobile}px`};
  padding: ${`${spacers.mobile}px`};
  order: 2;

  @media screen and (min-width: ${`${breakpoints.md}px`}) {
    margin: 0 ${`-${spacers.tablet}px`} ${`-${spacers.tablet}px`};
    padding: ${`${spacers.tablet}px`};
  }

  @media screen and (max-height: 600px) {
    margin-top: -100px;
  }
  @media screen and (min-width: ${`${breakpoints.xl}px`}) {
    order: 1;
    padding: 100px;
    border-radius: 100%;
    min-height: 900px;
    height: 100vh;
    min-width: 900px;
    width: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    margin-left: -20%;

    > div {
      position: absolute;
      min-width: 400px;
      width: 60vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      top: 0;
      overflow-y: scroll;
      left: ${`${spacers.desktop}px`};
    }
  }
`;

const Xing = styled.a`
  display: inline-block;
  width: 75px;
  height: 75px;
  background-image: url("/assets/gestaltungselemente/xing.svg");
  background-size: 100%;
  margin-bottom: ${`${spacers.mobile}px`};
`;

const LinkedIn = styled.a`
  display: inline-block;
  width: 75px;
  height: 75px;
  background-image: url("/assets/gestaltungselemente/linkedin.svg");
  background-size: 100%;
  margin-right: ${`${spacers.mobile}px`};
  margin-bottom: ${`${spacers.mobile}px`};
`;

const RoundContent: React.FC = () => {
  return (
    <RoundContentDiv>
      <div>
        <div>
          <Spacer />
          <Title color="white">Kontakt</Title>
          <Paragraph>
            {/* Bringen Sie die Kugeln ins Rollen –  */}Kontaktieren Sie mich
            für ein persönliches Gespräch!
          </Paragraph>
          <Address />
          <LinkedIn href="https://www.linkedin.com/in/rechsteiner-andrea-64338a68/?originalSubdomain=ch" />
        </div>
      </div>
    </RoundContentDiv>
  );
};

export default RoundContent;
