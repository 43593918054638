import * as React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { breakpoints, colors } from "../styles/__variables";

import DefaultLayout from "../layouts/Default";
import RoundContent from "../components/RoundContent";

const ContactFlex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;

  @media screen AND (min-width: ${breakpoints.xl}) {
    justify-content: flex-start;
  }
`;

const styles = {
  background: "url(/assets/images/background/photocase_5233_gross.jpg)",
  navColor: colors.blue.base,
  navColorDesktop: colors.blue.base,
};

const Kontakt = () => (
  <DefaultLayout styles={styles}>
    <Helmet
      title="Kontakt – Rundum HR"
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: `./assets/favicon/android-chrome-192x192.png`,
        },
      ]}
    />

    <ContactFlex>
      <RoundContent />
    </ContactFlex>
  </DefaultLayout>
);

export default Kontakt;
