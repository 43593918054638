import * as React from "react";
import styled from "styled-components";
import { breakpoints, heights } from "../styles/__variables";

interface PageStyles {
  styles: {
    background?: string;
    height?: string;
  };
}

const PageWrapperDiv = styled.div<PageStyles>`
  background: ${(props) =>
    props.styles.background === undefined || ""
      ? "#fff"
      : props.styles.background};
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 67px);
  height: 100%;
  flex: 1;
  margin-top: ${`${heights.nav.mobile}px`};
  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    margin-top: 0;
    min-height: 100vh;
    height: 100vh;
  }
`;

const PageWrapper: React.FC<PageStyles> = ({ children, styles }) => {
  // add wrapper here?
  return <PageWrapperDiv styles={styles}>{children}</PageWrapperDiv>;
};

export default PageWrapper;
