import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/__variables";

const SpacerDiv = styled.div`
  height: ${`${spacers.mobile}px`};
  min-height: ${`${spacers.mobile}px`};
  display: block;
  width: 100%;

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    height: ${`${spacers.tablet}px`};
    min-height: ${`${spacers.tablet}px`};
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    height: ${`${spacers.desktop}px`};
    min-height: ${`${spacers.dektop}px`};
  }
`;

const Spacer: React.FC = () => {
  return <SpacerDiv>&nbsp;</SpacerDiv>;
};

export default Spacer;
