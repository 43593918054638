import * as React from "react";
import styled from "styled-components";

import { useState } from "react";
import Navigation from "../components/navigation/Navigation";
import Header from "../components/navigation/Header";

import Wrapper from "./Wrapper";
import PageWrapper from "./PageWrapper";

import "normalize.css";
import "../styles/base.css";

// import { heights } from "../styles/__variables";

interface DefaultLayoutProps {
  styles: {
    background: string;
    height?: string;
    navColor: string;
    navColorDesktop: string;
  };
}
// import { fonts } from "../styles/variables";
// font-family: ${fonts.monospace};
const Layout = styled.div<DefaultLayoutProps>`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

// lifting state up -> closest common parent

// context -> defined somewhere, accessible from everywhere, not good standard solution, replaces redux

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, styles }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  return (
    <Layout>
      <PageWrapper styles={styles}>
        <Header
          navColorDesktop={styles.navColorDesktop}
          navColor={styles.navColor}
          isNavVisible={isNavVisible}
          setIsNavVisible={setIsNavVisible}
        />
        <Navigation
          isNavVisible={isNavVisible}
          setIsNavVisible={setIsNavVisible}
          navLinkColor={styles.navColor}
        />
        <Wrapper>{children}</Wrapper>
      </PageWrapper>
    </Layout>
  );
};

export default DefaultLayout;
